import { render, staticRenderFns } from "./TransactionSearchBox.vue?vue&type=template&id=0b83761c&scoped=true&lang=html"
import script from "./TransactionSearchBox.vue?vue&type=script&lang=ts"
export * from "./TransactionSearchBox.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b83761c",
  null
  
)

export default component.exports
import QInput from 'quasar/src/components/input/QInput.js';
import QRadio from 'quasar/src/components/radio/QRadio.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QInput,QRadio,QSpace,QBtn});
