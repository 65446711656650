
import { Component, Prop } from 'vue-property-decorator'
import Vue from '../VueBase'
import TransactionSearchBox from '@/components/TransactionSearchBox.vue'
import { UserRoles } from '@ht-lib/accounts-models'

@Component({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  components: { TransactionSearchBox },
  name: 'Default'
})
export default class extends Vue {
  @Prop({ type: String, default: null }) paymentId?: string

  get isPaymentProcessor (): boolean {
    const roles = this.$auth?.user?.profile?.roles ?? []
    return roles.includes(UserRoles.PAYMENT_PROCESSING_ADMIN) || roles.includes(UserRoles.PAYMENT_PROCESSING) || roles.includes(UserRoles.SOFTWARE_GODS)
  }
}
