"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthenticationType = exports.RunEnvironment = void 0;
var RunEnvironment;
(function (RunEnvironment) {
    RunEnvironment["SANDBOX"] = "apitest.cybersource.com";
    RunEnvironment["PRODUCTION"] = "api.cybersource.com";
    // BOA_SANDBOX = "bankofamerica.environment.sandbox",
    // BOA_PRODUCTION = "bankofamerica.environment.production",
    // IDC_SANDBOX = "apitest.cybersource.com",
    // IDC_PRODUCTION = "api.in.cybersource.com",
})(RunEnvironment = exports.RunEnvironment || (exports.RunEnvironment = {}));
var AuthenticationType;
(function (AuthenticationType) {
    AuthenticationType["JWT"] = "jwt";
    AuthenticationType["HTTP"] = "http_signature";
})(AuthenticationType = exports.AuthenticationType || (exports.AuthenticationType = {}));
