"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReportDownloadStatus = void 0;
var ReportDownloadStatus;
(function (ReportDownloadStatus) {
    ReportDownloadStatus["Okay"] = "Okay";
    ReportDownloadStatus["NotFound"] = "Not Found";
    ReportDownloadStatus["InvalidRequest"] = "Invalid Request";
    ReportDownloadStatus["Unauthorized"] = "Unauthorized";
    ReportDownloadStatus["Unknown"] = "Unknown";
})(ReportDownloadStatus = exports.ReportDownloadStatus || (exports.ReportDownloadStatus = {}));
