"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PayerAuthenticationAction = exports.isReceiptResponse = exports.PAYMENT_API_PATHS = exports.NormalizedResponses = exports.getPaymentSolutionName = exports.PaymentSolutionName = exports.PaymentSolution = void 0;
function isStringTruthy(value) {
    return typeof value === 'string' && value.length > 0;
}
var PaymentSolution;
(function (PaymentSolution) {
    PaymentSolution["None"] = "NO_PAYMENT_SOLUTION";
    PaymentSolution["Microform"] = "";
    PaymentSolution["VisaCheckout"] = "visacheckout";
    PaymentSolution["ApplePay"] = "001";
    PaymentSolution["CybersourceInAppSolution"] = "004";
    PaymentSolution["Masterpass"] = "005";
    PaymentSolution["AndroidPay"] = "006";
    PaymentSolution["ChasePay"] = "007";
    PaymentSolution["SamsungPay"] = "008";
    PaymentSolution["GooglePay"] = "012";
})(PaymentSolution = exports.PaymentSolution || (exports.PaymentSolution = {}));
var PaymentSolutionName;
(function (PaymentSolutionName) {
    PaymentSolutionName["None"] = "Unknown";
    PaymentSolutionName["Microform"] = "Card";
    PaymentSolutionName["VisaCheckout"] = "Visa Checkout";
    PaymentSolutionName["ApplePay"] = "Apple Pay";
    PaymentSolutionName["CybersourceInAppSolution"] = "Cybersource In-App Solution";
    PaymentSolutionName["Masterpass"] = "Masterpass";
    PaymentSolutionName["AndroidPay"] = "Android Pay";
    PaymentSolutionName["ChasePay"] = "Chase Pay";
    PaymentSolutionName["SamsungPay"] = "Samsung Pay";
    PaymentSolutionName["GooglePay"] = "Google Pay";
})(PaymentSolutionName = exports.PaymentSolutionName || (exports.PaymentSolutionName = {}));
function getPaymentSolutionName(paymentSolution) {
    switch (paymentSolution) {
        case PaymentSolution.None: return PaymentSolutionName.None;
        case PaymentSolution.Microform: return PaymentSolutionName.Microform;
        case PaymentSolution.VisaCheckout: return PaymentSolutionName.VisaCheckout;
        case PaymentSolution.ApplePay: return PaymentSolutionName.ApplePay;
        case PaymentSolution.CybersourceInAppSolution: return PaymentSolutionName.CybersourceInAppSolution;
        case PaymentSolution.Masterpass: return PaymentSolutionName.Masterpass;
        case PaymentSolution.AndroidPay: return PaymentSolutionName.AndroidPay;
        case PaymentSolution.ChasePay: return PaymentSolutionName.ChasePay;
        case PaymentSolution.SamsungPay: return PaymentSolutionName.SamsungPay;
        case PaymentSolution.GooglePay: return PaymentSolutionName.GooglePay;
        default: throw new Error(`Unknown payment solution ${paymentSolution}`);
    }
}
exports.getPaymentSolutionName = getPaymentSolutionName;
var NormalizedResponses;
(function (NormalizedResponses) {
    NormalizedResponses["authorized"] = "AUTHORIZED";
    NormalizedResponses["awaitingPayment"] = "AWAITING_PAYMENT";
    NormalizedResponses["contactCustomerServices"] = "CONTACT_CUSTOMER_SERVICES";
    NormalizedResponses["declined"] = "DECLINED";
    NormalizedResponses["payerAuthentication"] = "PAYER_AUTHENTICATION";
    NormalizedResponses["unsupportedCardType"] = "UNSUPPORTED_CARD_TYPE";
    NormalizedResponses["waitAndRetry"] = "WAIT_AND_RETRY";
})(NormalizedResponses = exports.NormalizedResponses || (exports.NormalizedResponses = {}));
var PAYMENT_API_PATHS;
(function (PAYMENT_API_PATHS) {
    PAYMENT_API_PATHS["checkout"] = "/checkout";
    PAYMENT_API_PATHS["googlePayFrame"] = "/google-pay/frame";
    PAYMENT_API_PATHS["googlePayGetCheckoutInfo"] = "/google-pay/checkout-info";
    PAYMENT_API_PATHS["applePayFrame"] = "/apple-pay/frame";
    PAYMENT_API_PATHS["applePayGetCheckoutInfo"] = "/apple-pay/checkout-info";
    PAYMENT_API_PATHS["applePayVerificationUrl"] = "/apple-pay/verification-url";
    PAYMENT_API_PATHS["notification"] = "/notification";
    PAYMENT_API_PATHS["receipt"] = "/receipt";
    PAYMENT_API_PATHS["pay"] = "/pay";
    PAYMENT_API_PATHS["payerAuthenticationSetup"] = "/payer-authentication-setup";
    PAYMENT_API_PATHS["stepUpResponse"] = "/step-up-response";
    PAYMENT_API_PATHS["refundSearch"] = "/refund-search";
    PAYMENT_API_PATHS["refundAction"] = "/refund";
    PAYMENT_API_PATHS["transactionHistory"] = "/transaction-history";
    PAYMENT_API_PATHS["noDepositTransaction"] = "/no-deposit-transaction";
})(PAYMENT_API_PATHS = exports.PAYMENT_API_PATHS || (exports.PAYMENT_API_PATHS = {}));
// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
function isReceiptResponse(value) {
    const _value = value;
    return _value != null &&
        typeof _value === 'object' &&
        isStringTruthy(_value.status) &&
        isStringTruthy(_value.orderReference) &&
        (_value.paymentReference == null || isStringTruthy(_value.paymentReference)) &&
        (_value.stepUpAccessToken == null || isStringTruthy(_value.stepUpAccessToken));
}
exports.isReceiptResponse = isReceiptResponse;
var PayerAuthenticationAction;
(function (PayerAuthenticationAction) {
    PayerAuthenticationAction["Authenticate"] = "Authenticate";
    PayerAuthenticationAction["Validate"] = "Validate";
})(PayerAuthenticationAction = exports.PayerAuthenticationAction || (exports.PayerAuthenticationAction = {}));
