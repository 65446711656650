
import { format as dateFormat, parseISO } from 'date-fns'
import { Component, Prop } from 'vue-property-decorator'
import { PaymentAction, ResponseStatus, TransactionResponse } from '@ht-lib/cybersource-models'
import Vue from '../VueBase'

const format = (val: string): string => dateFormat(parseISO(val), 'EEE dd MMM yyyy HH:mm:ss')

@Component({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  name: 'PaymentHistoryTable'
})
export default class extends Vue {
  @Prop({ type: Array, required: true }) history!: TransactionResponse[]
  @Prop({ required: true }) shouldShowRefund!: boolean

  pagination = {
    rowsPerPage: 10,
    sortBy: 'recorded',
    descending: false
  }

  tableHeaders = [
    { name: 'id', label: 'PSP', field: 'id', sortable: true, required: true, align: 'left' },
    { name: 'action', label: 'Action', field: 'action', sortable: true, required: true, align: 'left' },
    { name: 'status', label: 'Status', field: 'status', sortable: true, required: true, align: 'left' },
    { name: 'errorReason', label: 'Error', field: 'errorReason', sortable: true, required: true, align: 'left', style: 'max-width: 300px; white-space: normal !important;' },
    { name: 'transactionReason', label: 'Reason', field: 'transactionReason', sortable: true, required: true, align: 'left', style: 'max-width: 300px; white-space: normal !important;' },
    { name: 'reference', label: 'Payment Ref', field: 'reference', sortable: true, required: true, align: 'left' },
    { name: 'currency', label: 'Currency', field: 'currency', sortable: true, required: true, align: 'left' },
    { name: 'amount', label: 'Amount', field: 'amount', sortable: true, required: true, align: 'left' },
    { name: 'originalPaymentId', label: 'Original PSP', field: 'originalPaymentId', sortable: true, required: true, align: 'left' },
    { name: 'recorded', format, label: 'Date Recorded', field: 'recorded', sortable: true, required: true, align: 'left' }
  ]

  canRefund (row: TransactionResponse): boolean {
    return this.shouldShowRefund && row.action === PaymentAction.Payment && row.status === ResponseStatus.Authorized
  }
}
