"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CurrencyCodesIso4217 = exports.isIso4217 = void 0;
function isIso4217(currencyCode) {
    return typeof currencyCode === 'string' &&
        Object.values(CurrencyCodesIso4217).includes(currencyCode);
}
exports.isIso4217 = isIso4217;
// Updated from Wikipedia 2024-04-10
// https://en.wikipedia.org/wiki/ISO_4217
var CurrencyCodesIso4217;
(function (CurrencyCodesIso4217) {
    CurrencyCodesIso4217["AED"] = "AED";
    CurrencyCodesIso4217["AFN"] = "AFN";
    CurrencyCodesIso4217["ALL"] = "ALL";
    CurrencyCodesIso4217["AMD"] = "AMD";
    CurrencyCodesIso4217["ANG"] = "ANG";
    CurrencyCodesIso4217["AOA"] = "AOA";
    CurrencyCodesIso4217["ARS"] = "ARS";
    CurrencyCodesIso4217["AUD"] = "AUD";
    CurrencyCodesIso4217["AWG"] = "AWG";
    CurrencyCodesIso4217["AZN"] = "AZN";
    CurrencyCodesIso4217["BAM"] = "BAM";
    CurrencyCodesIso4217["BBD"] = "BBD";
    CurrencyCodesIso4217["BDT"] = "BDT";
    CurrencyCodesIso4217["BGN"] = "BGN";
    CurrencyCodesIso4217["BHD"] = "BHD";
    CurrencyCodesIso4217["BIF"] = "BIF";
    CurrencyCodesIso4217["BMD"] = "BMD";
    CurrencyCodesIso4217["BND"] = "BND";
    CurrencyCodesIso4217["BOB"] = "BOB";
    CurrencyCodesIso4217["BOV"] = "BOV";
    CurrencyCodesIso4217["BRL"] = "BRL";
    CurrencyCodesIso4217["BSD"] = "BSD";
    CurrencyCodesIso4217["BTN"] = "BTN";
    CurrencyCodesIso4217["BWP"] = "BWP";
    CurrencyCodesIso4217["BYN"] = "BYN";
    CurrencyCodesIso4217["BZD"] = "BZD";
    CurrencyCodesIso4217["CAD"] = "CAD";
    CurrencyCodesIso4217["CDF"] = "CDF";
    CurrencyCodesIso4217["CHE"] = "CHE";
    CurrencyCodesIso4217["CHF"] = "CHF";
    CurrencyCodesIso4217["CHW"] = "CHW";
    CurrencyCodesIso4217["CLF"] = "CLF";
    CurrencyCodesIso4217["CLP"] = "CLP";
    CurrencyCodesIso4217["CNY"] = "CNY";
    CurrencyCodesIso4217["COP"] = "COP";
    CurrencyCodesIso4217["COU"] = "COU";
    CurrencyCodesIso4217["CRC"] = "CRC";
    CurrencyCodesIso4217["CUP"] = "CUP";
    CurrencyCodesIso4217["CVE"] = "CVE";
    CurrencyCodesIso4217["CZK"] = "CZK";
    CurrencyCodesIso4217["DJF"] = "DJF";
    CurrencyCodesIso4217["DKK"] = "DKK";
    CurrencyCodesIso4217["DOP"] = "DOP";
    CurrencyCodesIso4217["DZD"] = "DZD";
    CurrencyCodesIso4217["EGP"] = "EGP";
    CurrencyCodesIso4217["ERN"] = "ERN";
    CurrencyCodesIso4217["ETB"] = "ETB";
    CurrencyCodesIso4217["EUR"] = "EUR";
    CurrencyCodesIso4217["FJD"] = "FJD";
    CurrencyCodesIso4217["FKP"] = "FKP";
    CurrencyCodesIso4217["GBP"] = "GBP";
    CurrencyCodesIso4217["GEL"] = "GEL";
    CurrencyCodesIso4217["GHS"] = "GHS";
    CurrencyCodesIso4217["GIP"] = "GIP";
    CurrencyCodesIso4217["GMD"] = "GMD";
    CurrencyCodesIso4217["GNF"] = "GNF";
    CurrencyCodesIso4217["GTQ"] = "GTQ";
    CurrencyCodesIso4217["GYD"] = "GYD";
    CurrencyCodesIso4217["HKD"] = "HKD";
    CurrencyCodesIso4217["HNL"] = "HNL";
    CurrencyCodesIso4217["HTG"] = "HTG";
    CurrencyCodesIso4217["HUF"] = "HUF";
    CurrencyCodesIso4217["IDR"] = "IDR";
    CurrencyCodesIso4217["ILS"] = "ILS";
    CurrencyCodesIso4217["INR"] = "INR";
    CurrencyCodesIso4217["IQD"] = "IQD";
    CurrencyCodesIso4217["IRR"] = "IRR";
    CurrencyCodesIso4217["ISK"] = "ISK";
    CurrencyCodesIso4217["JMD"] = "JMD";
    CurrencyCodesIso4217["JOD"] = "JOD";
    CurrencyCodesIso4217["JPY"] = "JPY";
    CurrencyCodesIso4217["KES"] = "KES";
    CurrencyCodesIso4217["KGS"] = "KGS";
    CurrencyCodesIso4217["KHR"] = "KHR";
    CurrencyCodesIso4217["KMF"] = "KMF";
    CurrencyCodesIso4217["KPW"] = "KPW";
    CurrencyCodesIso4217["KRW"] = "KRW";
    CurrencyCodesIso4217["KWD"] = "KWD";
    CurrencyCodesIso4217["KYD"] = "KYD";
    CurrencyCodesIso4217["KZT"] = "KZT";
    CurrencyCodesIso4217["LAK"] = "LAK";
    CurrencyCodesIso4217["LBP"] = "LBP";
    CurrencyCodesIso4217["LKR"] = "LKR";
    CurrencyCodesIso4217["LRD"] = "LRD";
    CurrencyCodesIso4217["LSL"] = "LSL";
    CurrencyCodesIso4217["LYD"] = "LYD";
    CurrencyCodesIso4217["MAD"] = "MAD";
    CurrencyCodesIso4217["MDL"] = "MDL";
    CurrencyCodesIso4217["MGA"] = "MGA";
    CurrencyCodesIso4217["MKD"] = "MKD";
    CurrencyCodesIso4217["MMK"] = "MMK";
    CurrencyCodesIso4217["MNT"] = "MNT";
    CurrencyCodesIso4217["MOP"] = "MOP";
    CurrencyCodesIso4217["MRU"] = "MRU";
    CurrencyCodesIso4217["MUR"] = "MUR";
    CurrencyCodesIso4217["MVR"] = "MVR";
    CurrencyCodesIso4217["MWK"] = "MWK";
    CurrencyCodesIso4217["MXN"] = "MXN";
    CurrencyCodesIso4217["MXV"] = "MXV";
    CurrencyCodesIso4217["MYR"] = "MYR";
    CurrencyCodesIso4217["MZN"] = "MZN";
    CurrencyCodesIso4217["NAD"] = "NAD";
    CurrencyCodesIso4217["NGN"] = "NGN";
    CurrencyCodesIso4217["NIO"] = "NIO";
    CurrencyCodesIso4217["NOK"] = "NOK";
    CurrencyCodesIso4217["NPR"] = "NPR";
    CurrencyCodesIso4217["NZD"] = "NZD";
    CurrencyCodesIso4217["OMR"] = "OMR";
    CurrencyCodesIso4217["PAB"] = "PAB";
    CurrencyCodesIso4217["PEN"] = "PEN";
    CurrencyCodesIso4217["PGK"] = "PGK";
    CurrencyCodesIso4217["PHP"] = "PHP";
    CurrencyCodesIso4217["PKR"] = "PKR";
    CurrencyCodesIso4217["PLN"] = "PLN";
    CurrencyCodesIso4217["PYG"] = "PYG";
    CurrencyCodesIso4217["QAR"] = "QAR";
    CurrencyCodesIso4217["RON"] = "RON";
    CurrencyCodesIso4217["RSD"] = "RSD";
    CurrencyCodesIso4217["RUB"] = "RUB";
    CurrencyCodesIso4217["RWF"] = "RWF";
    CurrencyCodesIso4217["SAR"] = "SAR";
    CurrencyCodesIso4217["SBD"] = "SBD";
    CurrencyCodesIso4217["SCR"] = "SCR";
    CurrencyCodesIso4217["SDG"] = "SDG";
    CurrencyCodesIso4217["SEK"] = "SEK";
    CurrencyCodesIso4217["SGD"] = "SGD";
    CurrencyCodesIso4217["SHP"] = "SHP";
    CurrencyCodesIso4217["SLE"] = "SLE";
    CurrencyCodesIso4217["SLL"] = "SLL";
    CurrencyCodesIso4217["SOS"] = "SOS";
    CurrencyCodesIso4217["SRD"] = "SRD";
    CurrencyCodesIso4217["SSP"] = "SSP";
    CurrencyCodesIso4217["STN"] = "STN";
    CurrencyCodesIso4217["SVC"] = "SVC";
    CurrencyCodesIso4217["SYP"] = "SYP";
    CurrencyCodesIso4217["SZL"] = "SZL";
    CurrencyCodesIso4217["THB"] = "THB";
    CurrencyCodesIso4217["TJS"] = "TJS";
    CurrencyCodesIso4217["TMT"] = "TMT";
    CurrencyCodesIso4217["TND"] = "TND";
    CurrencyCodesIso4217["TOP"] = "TOP";
    CurrencyCodesIso4217["TRY"] = "TRY";
    CurrencyCodesIso4217["TTD"] = "TTD";
    CurrencyCodesIso4217["TWD"] = "TWD";
    CurrencyCodesIso4217["TZS"] = "TZS";
    CurrencyCodesIso4217["UAH"] = "UAH";
    CurrencyCodesIso4217["UGX"] = "UGX";
    CurrencyCodesIso4217["USD"] = "USD";
    CurrencyCodesIso4217["USN"] = "USN";
    CurrencyCodesIso4217["UYI"] = "UYI";
    CurrencyCodesIso4217["UYU"] = "UYU";
    CurrencyCodesIso4217["UYW"] = "UYW";
    CurrencyCodesIso4217["UZS"] = "UZS";
    CurrencyCodesIso4217["VED"] = "VED";
    CurrencyCodesIso4217["VES"] = "VES";
    CurrencyCodesIso4217["VND"] = "VND";
    CurrencyCodesIso4217["VUV"] = "VUV";
    CurrencyCodesIso4217["WST"] = "WST";
    CurrencyCodesIso4217["XAF"] = "XAF";
    CurrencyCodesIso4217["XAG"] = "XAG";
    CurrencyCodesIso4217["XAU"] = "XAU";
    CurrencyCodesIso4217["XBA"] = "XBA";
    CurrencyCodesIso4217["XBB"] = "XBB";
    CurrencyCodesIso4217["XBC"] = "XBC";
    CurrencyCodesIso4217["XBD"] = "XBD";
    CurrencyCodesIso4217["XCD"] = "XCD";
    CurrencyCodesIso4217["XDR"] = "XDR";
    CurrencyCodesIso4217["XOF"] = "XOF";
    CurrencyCodesIso4217["XPD"] = "XPD";
    CurrencyCodesIso4217["XPF"] = "XPF";
    CurrencyCodesIso4217["XPT"] = "XPT";
    CurrencyCodesIso4217["XSU"] = "XSU";
    CurrencyCodesIso4217["XTS"] = "XTS";
    CurrencyCodesIso4217["XUA"] = "XUA";
    CurrencyCodesIso4217["XXX"] = "XXX";
    CurrencyCodesIso4217["YER"] = "YER";
    CurrencyCodesIso4217["ZAR"] = "ZAR";
    CurrencyCodesIso4217["ZMW"] = "ZMW";
    CurrencyCodesIso4217["ZWL"] = "ZWL";
})(CurrencyCodesIso4217 = exports.CurrencyCodesIso4217 || (exports.CurrencyCodesIso4217 = {}));
